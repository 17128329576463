<template>
  <div
    class="quickEstiamtedTag"
    :class="{
      'separate-line': showSeparateLine,
      'quickEstiamtedTag_align-normal': isNew
    }"
    data-wrap-height=".2133rem"
  >
    <EstimatedNoThresholdFloor
      v-if="isShowNoThresholdFloor"
      :estimated-info="estimatedInfo"
      @clickEstimatedTag="clickEstimatedTag"
    />
    <EstimatedTagNew
      v-else-if="isNew"
      :estimated-info="estimatedInfo"
      :has-link="hasLink"
      :is-wrap="isWrap"
      :config="config"
      :countdown-info="countdownInfo"
      @clickEstimatedTag="clickEstimatedTag"
    />
    <EstimatedTagOld
      v-else-if="isOld"
      :estimated-info="estimatedInfo"
      :has-link="hasLink"
      :is-wrap="isWrap"
      :countdown-info="countdownInfo"
      @clickEstimatedTag="clickEstimatedTag"
    />
  </div>
</template>

<script setup name="EstiamtedTag">
import { onMounted, defineAsyncComponent, watch, computed, inject } from 'vue'
import { debounce } from '@shein/common-function'

const EstimatedTagNew = defineAsyncComponent(() => import('./components/EstimatedTagNew.vue'))
const EstimatedTagOld = defineAsyncComponent(() => import('./components/EstimatedTagOld.vue'))
const EstimatedNoThresholdFloor = defineAsyncComponent(() => import('./components/EstimatedNoThresholdFloor.vue'))

import { ESTIMATED_STYLE_TYPES, ESTIMATED_DATA_TYPES } from '../../../../../hook/utils'

if(typeof window !== 'undefined') {
  window._exposeEstimatedTagFn_ = debounce({
    func: (args) => {
      triggerNotice(args)
    },
    wait: 1500,
  })
}

const { triggerNotice } = inject('analysisInstance')

const emits = defineEmits(['clickEstimatedTag', 'loaded'])
const props = defineProps({
  estimatedInfo: {
    type: Object,
    default: () => ({}),
  },
  isNoThresholdFloor: {
    type: Boolean,
    default: false,
  },
  isFloorType: {
    type: Boolean,
    default: false,
  },
  isWrap: {
    type: Number,
    default: -1,
  },
  countdownInfo: {
    type: Object,
    default: () => ({}),
  },
  config: {
    type: Object,
    default: () => ({}),
  },
})

const isNew = computed(() => {
  let { NEW, NEW_AND_NO_THRESHOLD_FLOOR } = ESTIMATED_STYLE_TYPES
  return [NEW, NEW_AND_NO_THRESHOLD_FLOOR].includes(props.estimatedInfo.styleType)
})

const goodsSn = computed(() => props.estimatedInfo.otherInfo.goodsSn)

const hasLink = computed(() => props.estimatedInfo.otherInfo.hasLink)

const isOld = computed(() => {
  let { OLD, OLD_NO_THRESHOLD, OLD_N_ESTIMATED } = ESTIMATED_STYLE_TYPES
  return [OLD, OLD_NO_THRESHOLD, OLD_N_ESTIMATED].includes(props.estimatedInfo.styleType)
})

// 百补 + 旧样式单独一行
const showSeparateLine = computed(() => {
  return isOld.value && props.estimatedInfo.otherInfo.isBrandDeals
})

const isShowNoThresholdFloor = computed(() => {
  return props.isNoThresholdFloor
})

const analysisData = computed(() => {
  let { estimatedInfo } = props || {}
  let { dataType, otherInfo, value, endTime, noThresholdFloor } = estimatedInfo || {}
  let { NO_SATISFY, AB_OVERLAP_NO_SATISFY } = ESTIMATED_DATA_TYPES
  let { isQuickAdd } = otherInfo
  let nothroshowtype = null
  if ([NO_SATISFY, AB_OVERLAP_NO_SATISFY].includes(dataType)) {
    nothroshowtype = noThresholdFloor ? 1 : 0
  }
  return {
    threshold: dataType,
    estimated_price: value.usdAmount,
    timelimit: noThresholdFloor?.endTime || endTime ? 1 : 0,
    showtype: isNew.value ? 1 : 0,
    clubprice: 0,
    location: isQuickAdd ? 'popup' : 'page', // 商详 page 加车弹窗 popup
    ...(nothroshowtype === null ? {} : { nothroshowtype }),
  }
})

const triggerExpose = () => {
  if (!isShowNoThresholdFloor.value && !isOld.value && !isNew.value) return false
  window._exposeEstimatedTagFn_({
    id: 'expose_estimated_price.comp_quick-add-cart-standard',
    data: analysisData.value,
  })
}

onMounted(async () => {
  triggerExpose()
})


watch(
  () => goodsSn.value,
  val => {
    if (val) triggerExpose()
  },
)

const clickEstimatedTag = () => {
  if (!hasLink.value) return
  triggerNotice({
    id: 'click_estimated_price.comp_quick-add-cart-standard',
    extraData: analysisData.value,
  })
  emits('clickEstimatedTag')
}

</script>

<style lang="less" scoped>
.quickEstiamtedTag {
  display: flex;
  z-index: 1;
  &_align-norma {
    align-self: normal;
  }
}

.quickEstiamtedTag_align-norma {
  align-self: normal;
}

.separate-line {
  flex-basis: 100%;
}
</style>
